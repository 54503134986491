<template>
  <div>
    <div class="banner">
      <div class="circle"></div>
      <div class="position-relative" style="z-index:1">
        <div class="subtitle7 primary">플랫폼파이 테마</div>
        <div class="mo-section-title-bold main margin-top-8">간단한 편집이 가능한 <br>디자인 빌더를 제공하는<br>플랫폼 서비스 테마</div>
        <div class="body4 sub2 margin-top-20">내 서비스에 맞는 테마를 선택하고<br>
          간단하게 디자인을 커스텀하여 <br> 플랫폼 서비스를 시작해보세요!</div>
        <button class="button is-primary-light margin-top-28 body4"
                style="width:160px;height:40px" @click="routerPush('/platformfy_info')">테마 주요기능 보기</button>
      </div>
    </div>
    <div class="margin-top-24">
      <div style="padding:0 16px">
        <div class="box-error-50"><img src="/static/icon/ic_star11.svg"> 모든 테마를 <b>무료로 체험해보세요.</b></div>
        <div class="h8 main" style="margin:20px 0">플랫폼파이 테마</div>
        <a href="https://moaform.com/q/PEF75O" target="_blank" style="color:#ff6600">
          <div class="item-survey">
            <div class="flex-center">
              <img src="/static/icon/emoticon_hmm.png" style="width:36px;height:36px">
            </div>
            <div class="body3">어떤 테마를 선택해야할지 어려우신가요?</div>

            <span class="body4-bold unselect flex-center">
                  내 서비스에 맞는 테마찾기
                  <svg-icon style="height: 24px"
                            :width="18"
                            :height="18"
                            icon="u_arrow-up-right" color="primary"></svg-icon>
                </span>
          </div>
        </a>
      </div>
      <div v-for="(card, idx) in themes" :key="`card-${card.id}`">
        <div class="gap" v-if="idx>0"></div>
        <card-product :card="card" style="padding:16px"></card-product>
      </div>
      <div style="padding:16px" v-if="page_num < total_page">
        <button class="button is-gray button-more body2-medium" @click="clickMore">더보기</button>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from "../../../component/SvgIcon";
  import CardProduct from "../../../component/CardProduct";
  export default {
    name: "Themes",
    components: {CardProduct, SvgIcon},
    created() {
      this.getData();
    },
    data() {
      return {
        page_num: 1,
        total_page: 1,
        themes: [],
        ordering: 'priority'
      }
    },
    methods: {
      clickMore() {
        this.page_num++;
        this.getData();
      },
      getData() {
        let params = {
          category: 59,
          fields: 'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate',
          page_num: this.page_num,
          page_length: 10
        };
        this.$axios.get('user/0/mapping/product', {params: params}).then(res => {
          this.themes = res.data.data;
          this.total_page = res.data.total_page;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .banner
    margin-top 110px
    background-color white
    padding 40px 0
    overflow hidden
    position relative
    text-align center

  .circle
    position absolute
    width 356px
    height 356px
    left calc(50% + 28px)
    top 50%
    background #f60
    opacity 0.22
    filter blur(100px)
    z-index 0

  .video
    z-index 1
    box-shadow 0 19px 20px 0 #FF660026

  .box-error-50
    display flex
    justify-content center
    align-items center
    background-color $error50
    color $error
    font-size 14px
    padding 12px
    border-radius 50px
    width 100%
    img
      margin-right 12px
    b
      margin-left 4px

  .grid-theme
    display grid
    grid-column-gap 36px
    grid-row-gap 48px
    grid-template-columns repeat(3, 1fr)

  .item-survey
    background-color $primary-light2
    border-radius 8px
    height 100%
    display flex
    flex-direction column
    padding 12px 60px
    text-align center

  .gap
    background-color $gray2
    width 100%
    height 8px

  .button-more
    width 100%
    height 44px
</style>
