<template>
  <div>
    <div class="pc">
      <theme-market-pc></theme-market-pc>
    </div>
    <div class="mobile">
      <theme-market-mobile></theme-market-mobile>
    </div>
  </div>
</template>

<script>
  import ThemeMarketPc from "./ThemeMarketPc";
  import ThemeMarketMobile from "./ThemeMarketMobile";
  export default {
    name: "ThemeMarket",
    components: {ThemeMarketMobile, ThemeMarketPc},
    created() {

    },
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>

</style>
